.artists-bg {
  top: 60px;
  margin-top: auto;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: normal;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 20rem;
    background-color: #c48a4b;
    filter: grayscale(100%);
    border-radius: 7px;
  }

.card-container {
  
    text-align:center;
    /* margin:auto 0; */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
    margin-bottom: 10em;
}

.card-container li{
    list-style-type:none;
    padding:2.5em 1.5em .5em 1.5em;
	  display:inline-block;
    text-align:center;
}

.card img {
    width: 20rem;
    height: 40rem;
    object-fit: cover;
    border-radius: 7px 7px 0px 0px;
}
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    filter: none;
  }

  hr{
    height: 1px;
    background-color: #000;
    border: none;
}

.card p, b{
  padding: 0rem 1.5rem 2rem;
  text-align: left;
}

  @media only screen and (max-width: 650px) {

    .artists-bg {
      height: auto;
      justify-content: center;
      align-items: center;
    }

    .card-container {
      padding: 0;
      margin: 1rem;
    }

  }

.card-container a:link {
    text-decoration: none;
    color: inherit;
}

.card-container a:visited {
    text-decoration: none;
    color: inherit;
}

.card-container a:hover {
    text-decoration: none;
    color: inherit;
}

.card-container a:active {
    text-decoration: none;
    color: inherit;
}