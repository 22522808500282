

.home {
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS", sans-serif;
  }

  .logoContainer {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .logoContainer img {
    max-width: 50%;
  }

  .logoContainer p {
    color: silver;
    font-family: OptimusPrinceps;
    font-size: 1.5em;
    bottom: 0;
  }

  
  .logoContainer button {
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #121619;
    height: 50px;
    width: 180px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .logoContainer button:hover {
    background-color: #07090a;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
  
  @media only screen and (max-width: 650px) {
    .home {
      justify-content: center;
      align-items: center;
    }
  
  
    .logoContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 80%;
      display: inline-block;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .logoContainer img {
        max-width: 100%;
        height: auto;
    }
  
    .logoContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .logoContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
  }
  