@font-face {
  font-family: "Normal";   /*Can be any text*/
  src: local("OptimusPrinceps"),
    url("./fonts/OptimusPrinceps.ttf") format("truetype");
}
/*Default styles for the entire app*/
.App-Section {
  font-family: OptimusPrinceps
}

.App {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: OptimusPrinceps, Helvetica, sans-serif;
    background-color: black;
  }
  
  body{
    padding: 0;
    margin: 0;
  }