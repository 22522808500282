.navbar {
    width: 100%;
    height: 65px;
    background-color: #2d2d2d;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    font-family: Normal;
    left: 0;
    z-index: 999;

  }
  
  .navbar .leftSide {
    position: sticky;
    top: 0;
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
  }
  
  .navbar .leftSide img {
    width: 70px;
  }
  
  .navbar .rightSide {
    position: sticky;
    top: 0;
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar a {
    color: #f6f0e2;
    text-decoration: none;
    margin: 20px;
  }

  .navbar a:hover {
    color: #c48a4b;
  }
  
  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: #f6f0e2;
    cursor: pointer;
  }
  
  .navbar .rightSide svg {
    font-size: 40px;
  }
  
  .navbar #open {
    padding-left: 0px;
  }
  .navbar #open img {
    display: none;
  }
  
  .navbar #close img {
    display: inherit;
  }
  
  .navbar #open .hiddenLinks {
    display: inherit;
    margin-left: 10px;
  }
  
  .navbar #close .hiddenLinks {
    display: none;
  }
  
  .navbar #open a {
    width: 70px;
    margin: 2px;
  }
  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 70px;
    }
    .navbar .leftSide {
      padding-left: 50px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .navbar .rightSide a {
      display: none;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 0px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .navbar .rightSide button {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  }
  