.artists-bg {
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS", sans-serif;
  }
  
  .location-card {
      /* Add shadows to create the "card" effect */
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      width: 40rem;
      height: 50rem;
      background-color: #c48a4b;
      filter: grayscale(100%);
      border-radius: 7px;
    }
  
  .location-card-container {
      text-align:center;
      margin:auto 0;
  }
  
  .location-card-container li{
      list-style-type:none;
      padding:.5em 1.5em .5em 1.5em;
        display:inline-block;
      text-align:left;
  }
  
  .location-card img {
      width: 40rem;
      height: 50%;
      border-radius: 7px 7px 0px 0px;
  }
    
    /* On mouse-over, add a deeper shadow */
    .location-card:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      filter: none;
    }
  
    hr{
      height: 1px;
      background-color: #000;
      border: none;
  }
  
  .location-card p, b{
    padding: 0rem 1.5rem 2rem;
    text-align: left;
  }
  
    @media only screen and (max-width: 650px) {
  
      .artists-bg {
        height: auto;
        justify-content: center;
        align-items: center;
      }
  
      .location-card-container {
        padding: 0;
        margin: 1rem;
      }
  
    }