.bio {
    background-color: silver;
    margin-bottom: 20px;
}
.bio-container {
    /* height: 1000px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    
}

.column1 img {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
    object-fit: cover;
    border-radius: 7px 7px 0px 0px;
}

.column1 {
    flex: 25%;
    max-width: 80%;
    padding: 1rem;
    border-radius: 8px;
    line-height: 1.2;
    font-size: 1.1rem;
  }

.column1 h2 {
  font-family: normal;
  font-size: 2rem;
}

  @media screen and (max-width: 600px) {
    .bio-container {
        grid-template-columns: 1fr;
   }

    .bio-container img {
        width: 100%;
        height: auto;
        /* object-fit: cover; */
        border-radius: 7px 7px 0px 0px;
    }

    .column .img {
        width: 100%
    }

    .profile-ig {

        margin-bottom: 0rem;
        width: '100%';
        height: 600px;
         
      }
    /* .column {
        width: 100%;
      } */
    /* .column1 {
      
      display: flex;
      flex-wrap: wrap;
    }
    .column2 {
        display: flex;
        flex-wrap: wrap;
    } */
  }