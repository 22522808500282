.footer {
    width: 100%;
    height: 180px;
    background-color: #2d2d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    font-family: Normal;
    font-size: large;
    /* position: sticky;
    bottom: 0; */
    z-index: 999;
  }
  
  .socialMedia svg {
    color: #f6f0e2;
    margin: 10px 10px 5px 10px;
    font-size: 40px;
    cursor: pointer;
  }

  .socialMedia svg:hover {
      color: #c48a4b;
      /*color: #f0d797;*/
  }

  
  .footer p {
    color: #f6f0e2;
  }

  .footer a:link {
    text-decoration: none;
    color: inherit;
}